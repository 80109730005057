@import 'styles/layout';

.container {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.content {
  max-width: 441px;
  padding-right: 16px;
}

.code {
  font-size: 18px;
  line-height: 22px;
}

.title {
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  margin-top: 4px;
}

.message {
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
}

.actions {
  margin-top: 32px;
}

@include media-max-target('phone') {
  .container {
    align-items: flex-start;
    flex-flow: column;
    justify-content: flex-start;
  }

  .image {
    order: -1;
    width: 130px;
  }

  .content {
    margin-top: 32px;
    padding-right: 0;
  }

  .code {
    font-size: 12px;
    line-height: 14px;
  }

  .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin-top: 8px;
  }

  .message {
    font-size: 14px;
    line-height: 18px;
  }
}
