@import 'styles/layout';
@import 'components/Logo/mixins';

.container {
  margin: 0 auto;
  max-width: 800px;
}

.logo {
  padding-top: 40px;
}

.link {
  display: block;
  text-decoration: none;
}

.content {
  margin-top: 180px;
}

@include media-max-target('phone') {
  .logo {
    @include logo-compact-mode;
  }

  .content {
    margin-top: 52px;
  }
}
